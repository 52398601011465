import React from 'react'
import styled, { css } from "styled-components"
import { HeaderService } from '../services/header-service';
import { InfoBannerService } from '../services/info-banner-service';
import { media } from "../styles/media-query-template"
import { Header } from './header';



const DEFAULT_ROW_MARGIN = '25px 0';
const ROW_TEXT_MARGIN = '15px auto 0 auto';
const DEFAULT_LARGE_ROW_TEXT_MARGIN = '-45px 0 0 0'; // else 25px 0 0 0 

function chooseMargin(props, marginKey) {
  return props.margin ? (props.margin[marginKey] ? props.margin[marginKey] : (typeof props.margin === 'string' ? props.margin : DEFAULT_ROW_MARGIN)) : DEFAULT_ROW_MARGIN;
}

const Row = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${props => props.verticalCentered === true && css`align-items: center;`};
  margin: ${props => chooseMargin(props, 'mobile')};
  width: 100%;
  font-size: 1rem;
  ${media.large`
    margin: ${props => chooseMargin(props, 'desktop')};
    flex-direction: row;
  `}
`

const RowText = styled.div`
  width: 100%;
  font-size: 0.75em;
  margin-bottom: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100px;
  ${media.large`
    font-size: 1.125em;
    padding-inline-end: 20px;
    height: 75px;
    margin-bottom: 0px;
    justify-content: flex-start;
  `}
`

const RowTextTitleWrapper = styled.div`
  font-weight: bold;
  font-size: ${props => props.fontSize ? props.fontSize : '1.125em'};
  margin-bottom: 7px;
  ${media.large`
    line-height: 1.75em; 
    font-size: ${props => props.fontSize ? props.fontSize : '1.5em'};
    margin-bottom: 12px;
  `}
`

const RowTextLeft = styled.div`
  width: 82%;
  margin: ${props => props.margin ? props.margin : ROW_TEXT_MARGIN};
  ${props => props.space && css`margin-right: 40px !important;`}
  font-size: 0.75em;
  order: 1;

  ${media.large`
    width: 100%;
    margin: ${props => props.margin ? props.margin : DEFAULT_LARGE_ROW_TEXT_MARGIN};
    font-size: 1.125em;
    line-height: 1.3125em;
    padding-inline-end: 20px;
    width: 70%;
    text-align: ${props => props.alignEnd ? 'end' : 'start'};
    order: inherit;

    *[dir='rtl'] & {
      text-align: start;
    }
  `}
`

const RowTextRight = styled.div`
  width: 82%;
  margin: ${props => props.margin ? props.margin : ROW_TEXT_MARGIN};
  ${props => props.space && css`margin-left: 40px !important;`}
  font-size: 0.75em;
  order: 1;
  ${media.large`
    width: 100%;
    margin: ${props => props.margin ? props.margin : DEFAULT_LARGE_ROW_TEXT_MARGIN};
    font-size: 1.125em;
    width: 60%;
    line-height: 1.3125em;
    padding-inline-start: 20px;
    order: inherit;
  `}
`

const RowImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  order: 2;
  ${media.large`
    order: inherit;
  `}
`

const RowImage = styled.img`
  flex: 0 1;
  margin: 0 auto;
  display: none;
  ${media.large`
    display: flex;
    height: ${props => props.imageHeight && props.imageHeight.length ? props.imageHeight : '340px'};
    width: ${props => props.imageWidth && props.imageWidth.length ? props.imageWidth : 'auto' };
  `}
`;

const RowMobileImage = styled.img`
  margin: 0 auto;
  height: ${props => props.imageHeight && props.imageHeight.length ? props.imageHeight : 'auto'};
  width: ${props => props.imageWidth && props.imageWidth.length ? props.imageWidth : '100%'};
  ${media.large`
    display: none;
  `}
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  ${media.large`
    text-align: start;
    justify-content: ${props => props.rightAlign ? 'flex-end' : 'flex-start'};
  `}
`

const Spacer = styled.div`
  height: 40px;
  width: 100%;
  ${media.large`
    height: 151px;
  `}
`
const rowLeftAndRightMargin = {
  mobile: '25px 0 10px 0'
}

const RowTextTitle = ({className = '', fontSize, children}) => {
  const newClassName = className + ' row-text-title';
  return (
    <RowTextTitleWrapper className={newClassName} fontSize={fontSize}>
      {children}
    </RowTextTitleWrapper>
  )
}

const RowLeft = ({title, children, imageIndex, imagesDesktop, imagesMobile, alignLeft = false, verticalCentered = true, topTitle = false, titleFontSize = '24px', space = false, imageHeight = "", imageWidth = ""}) => {
  let marginToUse = rowLeftAndRightMargin;
  if (!verticalCentered) {
    marginToUse = "0";
  }
  return (
    <>
      <Row margin="0">
        {topTitle && <RowTextTitle fontSize={titleFontSize}>{title}</RowTextTitle>}
      </Row>
      <Row margin={marginToUse} verticalCentered={verticalCentered}>
        <RowTextLeft className="row-text row-text-left" margin={verticalCentered ? "" : "0"} alignEnd={!alignLeft} space={space}>
          {!topTitle && <RowTextTitle fontSize={titleFontSize}>{title}</RowTextTitle>}
          {children}
        </RowTextLeft>
        <RowImageWrapper>
          <RowImage src={imagesDesktop[imageIndex]} imageHeight={imageHeight} imageWidth={imageWidth} />
          <RowMobileImage src={imagesMobile[imageIndex]} imageHeight={imageHeight} imageWidth={imageWidth} />
        </RowImageWrapper>
      </Row>
    </>
  )
}

const RowRight = ({title, children, imageIndex, imagesDesktop, imagesMobile, margin, verticalCentered = true, topTitle = false, titleFontSize = '24px', space = false, imageHeight = "", imageWidth = ""}) => {
  let marginToUse = rowLeftAndRightMargin;
  if (!verticalCentered) {
    marginToUse = "0";
  }
  else if (margin) {
    marginToUse = margin;
  }
  return (
    <>
      <Row margin="0">
        {topTitle && <RowTextTitle fontSize={titleFontSize}>{title}</RowTextTitle>}
      </Row>
      <Row margin={marginToUse} verticalCentered={verticalCentered}>
        <RowImageWrapper>
          <RowImage src={imagesDesktop[imageIndex]} imageHeight={imageHeight} imageWidth={imageWidth} />
          <RowMobileImage src={imagesMobile[imageIndex]} imageHeight={imageHeight} imageWidth={imageWidth} />
        </RowImageWrapper>
        <RowTextRight className="row-text row-text-right" margin={verticalCentered ? "" : "0"} space={space}>
          {!topTitle && <RowTextTitle fontSize={titleFontSize}>{title}</RowTextTitle>}
          {children}
        </RowTextRight>
      </Row>
    </>
  )
}

const smoothScrollTo = (selector) => {
  document.querySelector(selector).scrollIntoView({
    behavior: 'smooth'
  });
}

export { Row, RowText, ListWrapper, Spacer, RowLeft, RowRight, smoothScrollTo };
