/*
    Service that stores some properties about info banner, in order to get them out of info banner component. 
*/

import { ComponentProperty } from "./component-property";

class InfoBannerServiceImpl extends ComponentProperty {

    DEFAULT_HEIGHT = 100;
    constructor(height = 0) {
        super(height);
    }
}

const InfoBannerService = new InfoBannerServiceImpl();

export { InfoBannerService };
