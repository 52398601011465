import React from 'react'
import styled from 'styled-components'
import { media } from '../styles/media-query-template';

const LineWrapper = styled.div`
    margin-top: 10px;
    border-bottom: 4px solid #000000;
    margin-bottom: 13px;
    width: 50px;
    ${media.medium`
        width: ${props => (props.width === null || props.width === undefined ? 150 : props.width) }px;
        border-bottom-width: 6px;
    `}
`;

const Line = ({width}) => {
    return <LineWrapper width={width} />
}

export { Line }