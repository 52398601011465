import React from 'react'
import styled, { css } from 'styled-components'
import { Line } from './line';
import { media } from '../styles/media-query-template';

const defaultPadding = {
  mobile: "40px 7px",
  min1200: "50px 80px",
  min1550: "50px 135px"
};

const MiddleSectionPadding = {
  mobile: '40px 30px 0px',
  min1200: '50px 80px 0px',
  min1550: '50px 135px 0px'
};

const TitleFontSize = {
  desktop: {
    default: '3.125em',
    sub: '2.1875em'
  },
  mobile: {
    default: '1.5em',
    sub: '1.5em'
  }
};

const SectionStyle = styled.div`
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${props => (props.padding && props.padding.mobile) ? props.padding.mobile : defaultPadding.mobile};
  border-top: ${props => props.showLine ? '1' : '0'}px solid ${props => props.lineColor || '#313131'} ${props => props.keepLine && '!important'};
  ${media.large`
    padding: ${props => props.padding && props.padding.min1200 ? props.padding.min1200 : defaultPadding.min1200};
    border-top: ${props => props.showLine ? '1' : '0'}px solid ${props => props.lineColor || '#313131'};
  `}
  ${media.min1550`
    padding: ${props => (props.padding && props.padding.min1550) ? props.padding.min1550 : defaultPadding.min1550};
    border-top: ${props => props.showLine ? '1' : '0'}px solid ${props => props.lineColor || '#313131'};
  `}
  position: relative;
`

const TitleContent = styled.div`
  line-height: ${props => props.height && props.height.mobile ? props.height.mobile : 'normal'};
  font-weight: bold;
  font-size: ${props => TitleFontSize.mobile[props.sectionType]};
  ${media.medium`
    max-width: 986px;
    font-size: ${props => TitleFontSize.desktop[props.sectionType]};
    height: ${props => props.height && props.height.desktop ? props.height.desktop : 'inherit'};
    line-height: ${props => props.height && props.height.desktop ? props.height.desktop : 'normal'};
  `}
`;

const Title = styled.div`
  max-width: 339px;

  ${media.medium`
    max-width: ${props => props.width || '985px'};
  `}
`

const Label = styled.div`
  font-size: 0.75em;
  font-weight: bold;
  text-transform: uppercase;

  ${media.medium`
    font-size: 1.125em;
  `}
`

const Section = ({id, title, children, showLine, headerTitleLabel, lineWidth, titleWidth, showTitleLine, padding, titleHeight, lang, sectionType, keepLine, lineColor}) => {
  return (
    <SectionStyle id={id} showLine={showLine} padding={padding} keepLine={keepLine} lineColor={lineColor}>
      { title && <Title width={titleWidth}>
        { headerTitleLabel && <Label>{headerTitleLabel}</Label>}
        <TitleContent height={titleHeight} sectionType={sectionType ? sectionType : 'default'}>
          {title}
        </TitleContent>
      </Title>}
      { title && showTitleLine !== false && <Line width={lineWidth} />}
      { children }
    </SectionStyle>
  )
}

const Subsection = (props) => {
  return (
    <Section {...props} sectionType='sub' />
  );
};

export { Section, Subsection, Label, MiddleSectionPadding, defaultPadding }
