import React, { useEffect, useState } from 'react'
import { Layout } from "../../components/layout"
import { Section } from '../../components/section'
import { useLanguage, passLanguage, langPrefix } from '../../components/language-context'
import { Row, RowLeft, RowRight } from '../../components/offering-common-components';

import covid1DesktopImage from '../../images/covid/desktop/covid-1.png'
import covid2DesktopImage from '../../images/covid/desktop/covid-2.png'
import covid3DesktopImage from '../../images/covid/desktop/covid-3.png'
import covid4DesktopImage from '../../images/covid/desktop/covid-4.png'
import covid5DesktopImage from '../../images/covid/desktop/covid-5.png'
import covid6DesktopImage from '../../images/covid/desktop/covid-6.png'
import covid7DesktopImage from '../../images/covid/desktop/covid-7.png'
import covid8DesktopImage from '../../images/covid/desktop/covid-8.png'


import covid1MobileImage from '../../images/covid/mobile/covid-1.png'
import covid2MobileImage from '../../images/covid/mobile/covid-2.png'
import covid3MobileImage from '../../images/covid/mobile/covid-3.png'
import covid4MobileImage from '../../images/covid/mobile/covid-4.png'
import covid5MobileImage from '../../images/covid/mobile/covid-5.png'
import covid6MobileImage from '../../images/covid/mobile/covid-6.png'
import covid7MobileImage from '../../images/covid/mobile/covid-7.png'
import covid8MobileImage from '../../images/covid/mobile/covid-8.png'

import jumboImage from '../../images/covid/covid-banner.png';
import { JumboImage } from '../../components/solution-customized-components';
import styled from 'styled-components';
import { media, mediaWidths } from '../../styles/media-query-template';
import { FormType, RequestForm } from '../../components/request-form';
import { navigate } from '@reach/router';
import { mkEventEffect } from '../../components/utils';

const imagesDesktop = [covid1DesktopImage, covid2DesktopImage, covid3DesktopImage, covid4DesktopImage, covid5DesktopImage, covid6DesktopImage, covid7DesktopImage, covid8DesktopImage];
const imagesMobile = [covid1MobileImage, covid2MobileImage, covid3MobileImage, covid4MobileImage, covid5MobileImage, covid6MobileImage, covid7MobileImage, covid8MobileImage];

const HorizontalLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Panel = styled.div`
  width: 100%;

  ${media.medium`
    max-width: 50%;
    flex: 0 0 50%;
  `}
`;

const BlueButton = styled.button`
  margin-top: 30px;
  padding: 16px 28px 16px 37px;
  border-radius: 2px;
  border: none;
  background-color: #1f2d54;
  color: #FFFFFF;
  font-size: 1.125rem;
  font-weight: bold;
  align-self: flex-end;
  order: 2;
  width: 100%;
  cursor: pointer;
  display: block;
  ${media.large`
    align-self: inherit;
    order: 1;
    width: auto;
  `}
`;

const CovidRow = styled(Row)`
  margin: 20px 0 0 0 !important;
  font-size: 1.125rem;
`;

const CovidRowSubTitle = styled(CovidRow)`
  ${media.large`
    font-size: 2.1875rem;
  `};
  margin-bottom: 13px !important;
`

const RowWrapper = styled.div`
  .row-text{
    font-size: 1.125rem;
    margin: 30px 32px 0 32px !important;
    text-align: justify;
    ${media.large`
      text-align: left;
      margin: 0 !important;
    `}
  }

  .row-text-left {
    ${media.large`
      padding-inline-end: 40px !important;
    `}
  }

  .row-text-right {
    ${media.large`
      padding-inline-start: 40px !important;
    `}
  }

  .row-text-title{
    font-size: 1.5rem;
    margin: 0 32px 25px 32px !important;
    ${media.large`
      margin: 0 0 35px 0 !important;
      font-size: 2.1875rem;
    `}
  }
  
  &:not(:first-child) {
    margin-top: 62px;
    ${media.large`
      margin-top: 90px;
    `}
  }
`;

const RowRightCovid = ({children, title, imageIndex, margin, imageHeight = "", imageWidth = ""}) => {
  return (
    <RowWrapper>
      <RowRight title={title} imageIndex={imageIndex} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile} margin={margin} verticalCentered={false} topTitle={true} space={true} imageHeight={imageHeight} imageWidth={imageWidth}>
        {children}
      </RowRight>
    </RowWrapper>
  );
}

const RowLeftCovid = ({children, title, imageIndex, margin, imageHeight = "", imageWidth = ""}) => {
  return (
    <RowWrapper>
      <RowLeft title={title} imageIndex={imageIndex} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile} margin={margin} verticalCentered={false} topTitle={true} alignLeft={true} space={true} imageHeight={imageHeight} imageWidth={imageWidth}>
        {children}
      </RowLeft>
    </RowWrapper>
  );
}

const IndexPageBase = useLanguage(({location, msg, lang}) => {
  const [isMobileView, setIsMobileView] = useState(false);

  const paddingMainSection = {
    mobile: "40px 0px 10px 0px"
  }

  const marginFirstRow = {
    mobile: "15px 0 10px 0"
  }

  const navigateTo = (path) => {
    navigate(langPrefix(lang, path));
  };

  const toggleIsMobile = () => {
    setIsMobileView(window.innerWidth < mediaWidths.large);
  }

  const handleResize = () => {
    toggleIsMobile();
  }

  useEffect(() => {
    handleResize();
  }, []);
  useEffect(mkEventEffect("resize", handleResize), []);

  return (
    <Layout location={location}>
      <JumboImage src={jumboImage} />
      <Section title={msg('covid-title')} lineWidth={98} showTitleLine={true} lang={lang} id="analyze-section">
        <CovidRowSubTitle>
          <span dangerouslySetInnerHTML={ {__html: msg('covid-subtitle') }}></span>
        </CovidRowSubTitle>
        <CovidRow style={{display: 'inline'}}>
          { msg('covid-intro-text-1') }&nbsp;<b>{ msg('covid-intro-text-2') }</b>
        </CovidRow>
        <CovidRow style={{fontSize: '1.125rem'}}>
          { msg('covid-intro-text-3') }
        </CovidRow>
        <CovidRow style={{fontSize: '1.125rem'}}>
          { msg('covid-intro-text-4') }
        </CovidRow>
      </Section>
      <Section padding={paddingMainSection} showLine={true} lineColor="#470085">
        <RowLeftCovid title={msg('covid-section-1-title')} imageIndex={0} margin={marginFirstRow}>
          <span dangerouslySetInnerHTML={ {__html: msg('covid-section-1-text')} }></span>
          <BlueButton onClick={() => navigateTo('/product-services/touchless')}>{ msg('covid-section-1-label-button') }</BlueButton>
        </RowLeftCovid>

        <RowRightCovid title={msg('covid-section-2-title')} imageIndex={1}>
          <span dangerouslySetInnerHTML={ {__html: msg('covid-section-2-text')} }></span>
          <BlueButton onClick={() => navigateTo('/product-services/sentiment')}>{ msg('covid-section-2-label-button') }</BlueButton>
        </RowRightCovid>

        <RowLeftCovid title={msg('covid-section-3-title')} imageIndex={2} imageHeight={isMobileView ? 'auto' : '250px'}>
          <span dangerouslySetInnerHTML={ {__html: msg('covid-section-3-text')} }></span>
          <BlueButton onClick={() => navigateTo('/product-services/counter')}>{ msg('covid-section-3-label-button') }</BlueButton>
        </RowLeftCovid>

        <RowRightCovid title={msg('covid-section-4-title')} imageIndex={3}>
          <span dangerouslySetInnerHTML={ {__html: msg('covid-section-4-text')} }></span>
          <BlueButton onClick={() => navigateTo('/product-services/operations')}>{ msg('covid-section-4-label-button') }</BlueButton>
        </RowRightCovid>

        <RowLeftCovid title={msg('covid-section-5-title')} imageIndex={4}>
          <span dangerouslySetInnerHTML={ {__html: msg('covid-section-5-text')} }></span>
          <BlueButton onClick={() => navigateTo('/product-services/digital')}>{ msg('covid-section-5-label-button') }</BlueButton>
        </RowLeftCovid>

        <RowRightCovid title={msg('covid-section-6-title')} imageIndex={5}>
          <span dangerouslySetInnerHTML={ {__html: msg('covid-section-6-text')} }></span>
          <BlueButton onClick={() => navigateTo('/platform/insights')}>{ msg('covid-section-6-label-button') }</BlueButton>
        </RowRightCovid>

        <RowLeftCovid title={msg('covid-section-7-title')} imageIndex={6}>
          <span dangerouslySetInnerHTML={ {__html: msg('covid-section-7-text')} }></span>
          <BlueButton style={{marginBottom: '25px'}} onClick={() => navigateTo('/product-services/limitless')}>{ msg('covid-section-7-label-button') }</BlueButton>
        </RowLeftCovid>
      </Section>
      <Section padding={paddingMainSection} showLine={true} lineColor="#38b379">
        <RowRightCovid imageIndex={7} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile} verticalCentered={false} topTitle={true} showImageBorder={false} imageHeight={isMobileView ? '125px' : '151px'} imageWidth={isMobileView ? '288px' : '340px'}>
            <span dangerouslySetInnerHTML={ {__html: msg('covid-section-8-text')} }></span>
            <br/>
            <br/>
            <b>{ msg('covid-section-8-extra') }</b>
          </RowRightCovid>
      </Section>
      <Section id='request-form' title={msg('home-demo')} showLine={false} lang={lang}>
          <HorizontalLayout>
            <Panel />
            <Panel>
              <RequestForm formType={FormType.REQUEST_DEMO} fromPath={location.pathname}/>
            </Panel>
          </HorizontalLayout>
        </Section>
    </Layout> 
  )}
);

const IndexPage = passLanguage(IndexPageBase)

export default IndexPage

