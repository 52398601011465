import React from 'react';
import styled from 'styled-components'

import { useLanguage } from './language-context';
import { media } from '../styles/media-query-template';

const darkBlue = '#1f2d54';


const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 20px 0 5px 0;
    font-size: 1.5rem;
    font-weight: ${props => (props.bold ? '700' : '400')};
    ${media.large`
        margin: 35px 0 25px 0;
    `}
`

const CustomerJourneyRowStyle = styled(RowWrapper)`
    font-size: 1.125rem;
    margin: 20px 0 10px 0;
    ${media.medium`
        font-size: 1.5rem;
        margin: 35px 0 25px 0;
    `}
`;

const JumboImage = styled.img`
    width: 100%;
`
const LargeContactUsButton = styled.button`
    width: 100%;
    margin: 0 auto;
    height: 50px;
    border-radius: 2px;
    background: ${darkBlue};
    border: 1px solid ${darkBlue};
    font-size: 1.125rem;
    letter-spacing: normal;
    color: #ffffff;
    cursor: pointer;
    font-weight: bold;
    ${media.medium`
        width: 241px;
        margin: 0;
        margin-top: -10px;
    `}
`

const Row = ({children, margin, bold}) => {
    return (
        <RowWrapper margin={margin} bold={bold}>
            {children}
        </RowWrapper>
    );
}

const CustomerJourneyRow = useLanguage(({margin, msg}) => {
    return (
        <CustomerJourneyRowStyle margin={margin} bold={true}>
            {msg('customer-journey-info')}
        </CustomerJourneyRowStyle>
    )
});

export { JumboImage, Row, LargeContactUsButton, CustomerJourneyRow}
